import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { PedidoSequenciadoHistorico } from 'src/shared/models/pedido-sequenciado-historico.models';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/ApiResponse';

@Injectable({
  providedIn: 'root'
})
export class PedidoSequenciadoHistoricoService {

  private pedidoSequenciadoHistoricoUrl = environment.abacoApiUrl
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http:HttpClient,
  ){}

  getPedidoSequenciadoHistoricos(pedidoId: number, fornecedorId: number) {
    return this.http.get<ApiResponse<PedidoSequenciadoHistorico[]>>(`${this.pedidoSequenciadoHistoricoUrl}/api/PedidoSequenciadoHistorico/ListarPedidos?pedidoId=${pedidoId}&fornecedorId=${fornecedorId}`)
  }
    
  updatePedidoSequenciadoHistorico(pedidoSequenciadoHistorico: PedidoSequenciadoHistorico): Observable<any> {
    return this.http.put(this.pedidoSequenciadoHistoricoUrl, pedidoSequenciadoHistorico, this.httpOptions)
  }
}
