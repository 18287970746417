<sdds-theme name="scania"></sdds-theme>

<div class="sdds-card">
    <div class="sdds-card-header">
        <h5 class="sdds-card-headline">Pedidos Sequenciados - Histórico</h5>
        <h6 class="sdds-card-sub-headline">Pedidos</h6>
    </div>
    <div class="sdds-card-body">
        <div class="fields-find">
            <div class="pedido">
                <div style="width: 480px">
                    <sdds-textfield  [(ngModel)]="parametroPesquisa" ngDefaultControl size="md" max-length="300" label-position="inside" placeholder="Pesquisar por numero">
                        <span slot="sdds-label">Pedidos</span>              
                    </sdds-textfield> 
                </div>
                <div class="action-buttons" >
                    <button mat-icon-button class="iconSearch" (click)="searchPedido(parametroPesquisa)">
                        <mat-icon aria-hidden="false" aria-label="iconSearch" fontIcon="search"></mat-icon>
                        <i class="iconSearch" alt="search" src="assets\icons\lupa.svg"></i>
                    </button>
                </div> 
            </div>
        </div>  
          
        <div class="sdds-divider-light-border-top"></div>
            <div class="sdds-card-body">
                <table class="sdds-table" aria-label="Tabela Pedido" style="width:100%;">
                    <thead class="sdds-table__header">
                        <tr class="sdds-table__row">
                            <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Pedido</th>
                            <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Fornecedor</th>
                            <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Data de Validade</th>
                        </tr>
                    </thead>
                    <tbody class="sdds-table__body">
                        <tr class="sdds-table__row" *ngFor="let pedidoId of pedidoIds" (click)=selectedLine(pedidoId.id) [ngClass]="{'background' : pedidoId.selected}">
                            <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{pedidoId.pedidoId}}</td>
                            <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{pedidoId.fornecedorId}}</td>
                            <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{pedidoId.dataValidade | date: 'short'}}</td>                           
                        </tr>
                    </tbody>
                </table>
            </div>
        <tds-button test="Open Modal" id="my-modal-button"></tds-button> 
        <tds-modal 
            header="The buttons for the Modal only works in the canvas tab" 
            selector="#my-modal-button" 
            show 
            id="my-modal" 
            size="lg" 
            actions-position="static" 
            > </tds-modal>
            
        <div class="sdds-card-footer">
            <h2 mat-dialog-title></h2>
                <mat-dialog-actions>
                    <sdds-button type="primary" size="sm" text="Selecionar" style="margin: 8px 8px 0px 0px;" mat-button (click)="sendToFather()"></sdds-button>
                    <sdds-button  size="sm" text="Fechar" style="margin: 8px 8px 0px 0px;" mat-button (click)="closed()"></sdds-button>
                </mat-dialog-actions>
        </div>      
    </div>
<app-pedidoId-search></app-pedidoId-search>
