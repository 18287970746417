import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { PedidoIdService } from 'src/shared/services/pedido-id.service';
import { ModalService } from 'src/shared/services/modal.service';
import { PedidoId } from 'src/shared/models/pedido-id';


@Component({
    selector: 'app-pedido-id',
    standalone: true,
    imports: [ CommonModule, RouterModule, MatButtonModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, MatTableModule,MatTableModule, HttpClientModule, MatIconModule, FormsModule],
    templateUrl: './pedido-id.component.html',
    styleUrl: './pedido-id.component.css',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    changeDetection: ChangeDetectionStrategy.OnPush,
    
})
export class PedidoIdComponent { 

  pedidoIds: PedidoId[] = [];
  parametroPesquisa: string = ''

  constructor(
      public dialog: MatDialog,
      modalService: ModalService,
      private pedidoIdService: PedidoIdService,
      private changes: ChangeDetectorRef,
      public dialogRef: MatDialogRef<PedidoIdComponent>,
      @Inject(MAT_DIALOG_DATA) public pedidoId: PedidoId,
      ) {}

  searchPedido(parametroPesquisa: any): void {
    const trimmedParam = parametroPesquisa.trim();

    if(trimmedParam == '') {
      this.getAllPedidos();
    } else {
      this.getPedidoId(trimmedParam)
    }
  }

  getAllPedidos(): void {
    this.pedidoIdService.getPedidoIds().subscribe(
      OperationResult => {
        this.pedidoIds = OperationResult.content;
        this.changes.detectChanges();
        console.log(OperationResult.content)
      }
    )
  }

  getPedidoId(pedidoId: number) : void{ 
    this.pedidoIdService.getPedidoId(pedidoId).subscribe (
      response => {
        this.pedidoIds = this.returnAsArray(response.content)
        this.changes.detectChanges();
      }
    )
  }

  private returnAsArray(response: any){
    const array = Array.isArray(response)

    if(array)
        return response;
    else
        return [response];
  }

  selectedLine(id:number){
    this.pedidoIds = this.pedidoIds.map(x => {
      x.id === id? x.selected = true : x.selected = false;
      return x;
    });
  }

  sendToFather() {
    let filteredRow = this.pedidoIds.find(x => x.selected)
    this.dialogRef.close(filteredRow);
  }
 
  closed(): void {
    this.dialogRef.close();
}

}
