import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

import { PedidoSequenciadoHistorico } from '../shared/models/pedido-sequenciado-historico.models';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, RouterOutlet, HttpClientModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppComponent {
  title = 'front-end-template';

  pedidoSequenciadoHistorico = {} as PedidoSequenciadoHistorico;
  pedidoSequenciadoHistoricos: PedidoSequenciadoHistorico[] | undefined;

}