<sdds-theme name="scania"></sdds-theme>
<nav class='sdds-nav'>
  <div class='sdds-nav__left'>
    <div class='sdds-nav__app-name'>ABACO Logístico</div>
  </div>

  <div class='sdds-nav__center'>
    <ul class='sdds-nav__inline-menu'>

      <li class='sdds-nav__item' routerLink="welcome" routerLinkActive="sdds-nav__item--active">
        <a class='sdds-nav__item-core'>
          <p class='sdds-nav__item-core-text'>Bem vindo</p>
        </a>
      </li>

      <li class='sdds-nav__item' routerLink="pedido-sequenciado-historico" routerLinkActive="sdds-nav__item--active">
        <a class='sdds-nav__item-core'>
          <p class='sdds-nav__item-core-text'>Pedidos Sequenciados - Histórico</p>
        </a>
      </li>
    </ul>
  </div>

  <div class='sdds-nav__right'>
    <a class='sdds-nav__item sdds-nav__app-logo' href='#'></a>
  </div>

</nav>
<main class="content-container">
  <router-outlet></router-outlet>
</main>