import { Pipe, type PipeTransform } from '@angular/core';
import { Fornecedor } from '../models/fornecedor';

@Pipe({
  name: 'appIdComMf',
  standalone: true,
})
export class IdComMfPipe implements PipeTransform {

  transform(value: Fornecedor) {
    return `${value.id} - ${value.mf}`;
  }

}
