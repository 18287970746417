import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { Fornecedor } from '../../../shared/models/fornecedor';
import { FornecedorService } from 'src/shared/services/fornecedor.service';
import { ModalService } from 'src/shared/services/modal.service';
import { IdComMfPipe } from 'src/shared/pipe/idComMf.pipe';

@Component({
    selector: 'app-fornecedor',
    standalone: true,
    imports: [ CommonModule, RouterModule, MatButtonModule, MatDialogActions, MatDialogClose, MatCheckboxModule, MatDialogTitle, MatDialogContent, MatTableModule, HttpClientModule, FormsModule, MatIconModule, IdComMfPipe ],
    templateUrl: './fornecedor.components.html',
    styleUrls: ['./fornecedor.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    schemas: [CUSTOM_ELEMENTS_SCHEMA], 
})
export class FornecedorComponent { 
    fornecedores : Fornecedor[] = [];
    parametroPesquisa: string = ''

    constructor(
        public dialog: MatDialog,
        modalService: ModalService,
        private fornecedorService: FornecedorService,
        private changes: ChangeDetectorRef,
        public dialogRef: MatDialogRef<FornecedorComponent>,
        @Inject(MAT_DIALOG_DATA) public fornecedor: Fornecedor) {}
 
    searchFornecedor(parametroPesquisa: any): void {
        const trimmedParam = parametroPesquisa.trim();

        if(trimmedParam == '')
            this.getAllFornecedores();
        else{
            Number.isNaN(parseInt(trimmedParam)) ? this.getFornecedorPorNome(trimmedParam) : this.getFornecedorPorId(trimmedParam)
        }
    }
    
    getAllFornecedores(): void {
        this.fornecedorService.getFornecedores().subscribe (
            OperationResult => {
                this.fornecedores = OperationResult.content;
                this.changes.detectChanges();
                console.log(OperationResult.content)
            }
        )
    }

    getFornecedorPorId(id: number): void {
        this.fornecedorService.getFornecedorId(id).subscribe (
            response => {
                this.fornecedores = this.returnAsArray(response.content);
                this.changes.detectChanges();
            }
        );
    }
    
    getFornecedorPorNome(nome: string ): void {
        this.fornecedorService.getFornecedorPorNome(nome).subscribe (
            response => {
                this.fornecedores = this.returnAsArray(response.content);
                this.changes.detectChanges();
            }
        );
    }

    private returnAsArray(response: any){
        const array = Array.isArray(response)

        if(array)
            return response;
        else
            return [response];
    }

    selectLine(id: number){
        this.fornecedores = this.fornecedores.map(x => {
            x.id === id? x.selected = true : x.selected = false;
            return x;
        });
    }

    sendToFather(){
        let filteredRow = this.fornecedores.find(x => x.selected)
        this.dialogRef.close(filteredRow);
    }

    closed(): void {
        this.dialogRef.close();
    }

}