import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';

// @ts-ignore
import { defineCustomElements, addTheme } from '@scania/components';
// @ts-ignore
import { theme as scania } from '@scania/theme-light';
import { AppComponent } from './app/app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { App_Routes} from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient } from '@angular/common/http';

defineCustomElements();


addTheme(scania);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [importProvidersFrom(BrowserModule), provideRouter(App_Routes), FormsModule, ReactiveFormsModule, provideAnimationsAsync(), provideHttpClient()]
})
  .catch(err => console.error(err));
