import { CommonModule, NgFor } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectorRef, Component } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ActivatedRoute, RouterLink } from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry, MatIconModule} from '@angular/material/icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ModalService } from 'src/shared/services/modal.service';
import { PedidoSequenciadoHistorico } from '../../shared/models/pedido-sequenciado-historico.models';
import { FornecedorComponent } from './fornecedor-modal/fornecedor.component';
import { PedidoIdComponent } from './pedido-id-modal/pedido-id.component';
import { PedidoSequenciadoHistoricoService } from 'src/shared/services/pedido-sequenciado-historico.service';
import { Fornecedor } from 'src/shared/models/fornecedor';
import { PedidoId } from 'src/shared/models/pedido-id';

@Component({
    selector: 'app-pedido-sequenciado-historico',
    templateUrl: './pedido-sequenciado-historico.component.html',
    styleUrls: ['./pedido-sequenciado-historico.component.css'],
    standalone: true,
    imports: [ NgFor, RouterLink, MatIconModule, MatDialogModule, HttpClientModule, FormsModule, MatFormFieldModule, CommonModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class PedidoSequenciadoHistoricoComponent {
  
  pedidoSequenciadoHistoricos: PedidoSequenciadoHistorico[] = [];
  alertsEnabled: any;
  pedidoSequenciadoHistorico: any;
  fornecedorSelecionado!: Fornecedor;
  pedidoIdSelecionado!: number;
  selecionarFornecedor: any;
  nomeFornecedorSelecionado: string = '';
  

  constructor(
    public dialog: MatDialog, 
    modalService: ModalService,
    iconRegistry: MatIconRegistry, 
    sanitizer: DomSanitizer,
    private pedidoSequenciadoHistoricoService: PedidoSequenciadoHistoricoService,
    private route: ActivatedRoute,
    private changes: ChangeDetectorRef,

  ) {}

  openDialogFornecedor(): void {
    const dialogRef = this.dialog.open(FornecedorComponent, {
      width:'450',
      data: {},
      disableClose: true
    });
    
    dialogRef.afterClosed()
    .subscribe( 
      (result : Fornecedor) => {
        this.fornecedorSelecionado =  result;
        this.nomeFornecedorSelecionado = result.nome;
      }
    )
  }
  
  openDialogPedido(): void {
    const dialogRef = this.dialog.open(PedidoIdComponent, {
      width:'450',
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed()
    .subscribe(
      (result: PedidoId) => {
        this.pedidoIdSelecionado = result?.pedidoId
      }
    )
  }
  
  getPedidoSequenciadoHistorico() : void {
    this.pedidoSequenciadoHistoricoService.getPedidoSequenciadoHistoricos(this.pedidoIdSelecionado, this.fornecedorSelecionado.id).subscribe(
      pedidoSequenciadoHistoricosResponse=> {
      this.pedidoSequenciadoHistoricos= this.returnAsArray( pedidoSequenciadoHistoricosResponse.content);
      this.changes.detectChanges();        
    })
  }

  private returnAsArray(response: any){
    const array = Array.isArray(response)

    if(array)
        return response;
    else
        return [response];
}
}
