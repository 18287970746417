 <sdds-theme name="scania"></sdds-theme>

<div class="sdds-card">
    <div class="sdds-card-header">
        <h5 class="sdds-card-headline">Pedidos Sequenciados - Histórico</h5>
        <h6 class="sdds-card-sub-headline">Fornecedores</h6>
    </div>
    <div class="sdds-card-body">
        <div class="fields-find">
            <div class="fornecedor">
                <div style="width: 480px">
                    <sdds-textfield [(ngModel)]="parametroPesquisa" ngDefaultControl size="md" max-length="300" label-position="inside" placeholder="Pesquisar por nome ou numero">
                        <span slot="sdds-label">Fornecedores</span>  
                    </sdds-textfield>
                </div>
                <div class="action-buttons" >
                    <button mat-icon-button matTooltip="Primary" class="iconSearch" (click)="searchFornecedor(parametroPesquisa)" >
                        <mat-icon aria-label="iconSearch" fontIcon="search">search</mat-icon>
                            <i class="iconSearch" alt="search" src="assets\icons\lupa.svg"></i>
                    </button>
                </div> 
            </div> 
        </div>   

        <div class="sdds-divider-light-border-top"></div>
        <div class="sdds-card-body">
            <table class="sdds-table" aria-label="Tabela Fornecedores" style="width:100%;">
                <thead class="sdds-table__header">
                    <tr class="sdds-table__row" >
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Nome</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Código</th>
                    </tr>
                </thead>
                <tbody class="sdds-table__body">
                    <tr class="sdds-table__row" *ngFor="let fornecedor of fornecedores" (click)=selectLine(fornecedor.id) [ngClass]="{ 'background' : fornecedor.selected }">
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{fornecedor.nome}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{fornecedor | appIdComMf}}</td> 
                    </tr>
                </tbody>
            </table>
        </div>
        <tds-button test="Open Modal" id="my-modal-button"></tds-button> 
        <tds-modal 
            header="The buttons for the Modal only works in the canvas tab" 
            selector="#my-modal-button" 
            show 
            id="my-modal" 
            size="lg" 
            actions-position="static" >
        </tds-modal> 
       
        <div class="sdds-card-footer">
            <h2 mat-dialog-title></h2>
                <mat-dialog-actions>
                    <sdds-button type="primary" size="sm" text="Selecionar" style="margin: 8px 8px 0px 0px;"  mat-button (click)="sendToFather()"></sdds-button>
                    <sdds-button  size="sm" text="Fechar" style="margin: 8px 8px 0px 0px;" mat-button (click)="closed()"></sdds-button>
              </mat-dialog-actions>
        </div>
    </div>
</div>
<app-fornecedor-search></app-fornecedor-search>