import { HttpClient,  HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PedidoId } from 'src/shared/models/pedido-id';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PedidoIdService {

  private pedidoIdUrl = environment.abacoApiUrl;
  
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  
  pedidoIdsUrl : PedidoId [] = [];

  constructor(
      private http:HttpClient,
  ){}

  getPedidoIds() {
      return this.http.get<ApiResponse<PedidoId[]>>(`${this.pedidoIdUrl}/api/Pedido/PesquisarPedidos`);
  }

  getPedidoId(pedidoId: number) {
    return this.http.get<ApiResponse<PedidoId[]>>(`${this.pedidoIdUrl}/api/Pedido/PesquisarPedidoPorId?pedidoId=${pedidoId}`);
  }
}

