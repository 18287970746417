import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Fornecedor } from 'src/shared/models/fornecedor';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class FornecedorService
{ 
    private fornecedorUrl = environment.abacoApiUrl;
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    
    fornecedoresUrl : Fornecedor [] = [];

    constructor(
        private http:HttpClient,
    ){}

    getFornecedores(){
        return this.http.get<ApiResponse<Fornecedor[]>>(`${this.fornecedorUrl}/api/Fornecedor/PesquisarTodosFornecedores`)
          
    }

    getFornecedorPorNome(nome: string) {
        return this.http.get<ApiResponse<Fornecedor[]>>(`${this.fornecedorUrl}/api/Fornecedor/PesquisarFornecedorPorNome?nome=${nome}`)
        
    }
     
    getFornecedorId(id: number) {
      return this.http.get<ApiResponse<Fornecedor[]>>(`${this.fornecedorUrl}/api/Fornecedor/PesquisarFornecedorPorId?id=${id}`)
        
    }  
   
}

