<sdds-theme name="scania"></sdds-theme>
<div class="sdds-card">
    <div class="sdds-card-header">
        <h6 class="sdds-card-headline">Pedidos Sequenciados - Histórico</h6>
        <h6 class="sdds-card-sub-headline">Lista de Pedidos</h6>
    </div>
    <div class="sdds-divider-light-border-top"></div>

    <div class="sdds-card-body">
        <div class="fields-find">
            <div class="fornecedor">
                <div style="width: 300px">
                    <sdds-textfield [(ngModel)]="nomeFornecedorSelecionado" ngDefaultControl size="md" max-length="300" label-position="inside" placeholder="Fornecedor">
                        <span slot="sdds-label">Fornecedores</span>  
                    </sdds-textfield>
                </div>
                <div class="action-buttons" >
                    <button mat-icon-button class="iconSearch" (click)="openDialogFornecedor()">
                        <mat-icon aria-hidden="false" aria-label="iconSearch" fontIcon="open_in_new"></mat-icon>
                        <i class="iconSearch" src="assets\icons\lupa.svg"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="fields-find">    
            <div class="pedido">
                <div style="width: 300px"> 
                    <sdds-textfield [(ngModel)]="pedidoIdSelecionado" ngDefaultControl size="md" max-length="300" label-position="inside" placeholder="Pedido">
                        <span slot="sdds-label">Pedidos</span>  
                    </sdds-textfield> 
                </div>
                <div class="action-buttons" >
                    <button mat-icon-button class="iconSearch" (click)="openDialogPedido()">
                        <mat-icon aria-hidden="false" aria-label="iconSearch" fontIcon="open_in_new"></mat-icon>
                        <i class="iconSearch" alt="lupa" src="assets\icons\lupa.svg"></i>
                    </button>
                </div> 
                
            </div>
        </div>       
    </div>
     

    <div class="sdds-divider-light-border-top"></div>
    <div class="sdds-card-body">
        <table class="sdds-table" aria-label="Tabela de Pedido Sequenciado Historico">
            <thead class="sdds-table__header">
                <tr class="sdds-table__row">
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Fornecedor</th>
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Pedido</th>
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Chassi</th>
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Pop Id</th>
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">CM</th>
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Fluxo</th>

                </tr>
            </thead>
            <tbody class="sdds-table__body">
                <tr class="sdds-table__row" *ngFor="let pedidoSequenciadoHistorico of pedidoSequenciadoHistoricos">
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{pedidoSequenciadoHistorico.pedidoId}}</td>
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{pedidoSequenciadoHistorico.fornecedorId}}</td>
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{pedidoSequenciadoHistorico.chassi}}</td>
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{pedidoSequenciadoHistorico.popId}}</td>
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{pedidoSequenciadoHistorico.cm}}</td>
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{pedidoSequenciadoHistorico.fluxoId}}</td>
                </tr>
            </tbody>
        </table>
    </div>    
    <div class="sdds-card-footer">
        <h2 mat-dialog-title></h2>
        <sdds-button type="primary" size="sm" (click)="getPedidoSequenciadoHistorico()" text="Pesquisar" style="margin: 8px 8px 0px 0px;">
            <p *ngIf=" true, else anotherBlock"></p>
            <ng-template #anotherBlock>
                <p>Pedido Não encontrado!</p>
            </ng-template>
    </sdds-button>
    </div> 
</div>
<app-pedidoSequenciadoHistorico-search></app-pedidoSequenciadoHistorico-search>

